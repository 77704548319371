import(/* webpackMode: "eager" */ "/app/node_modules/@qlight/q-components/dist/style.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CRM/Google/AnalyticsBody.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CRM/Hubspot/HubspotTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizationProvider"] */ "/app/src/components/Localization/LocalizationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Navigation/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Navigation/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Navigation/NavProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/app/src/utils/Cart/cartProvider.tsx");
